.footer {
  color: $main-blue-color;

  a {
    color: $main-blue-color;
  }

  &__menus{
    @include respond-below(md) {
     padding-top: 15px;
     padding-bottom: 15px;
    }
  }

  &__title{
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    padding-bottom: 20px;
    &--upper{
      text-transform: uppercase;
    }
  }

  .menu-title {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    padding-bottom: 20px;
    min-height: 39px;
    text-transform: uppercase;
  }

  &--top {
    margin-top: 55px;
  }

  &--bottom {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 12px;
    line-height: 14px;
    color: #FEFEFE;
    background-color: $main-blue-color;
    margin-top: 65px;
  }

  &__text {
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 39px;
    & i{
      font-size: 1.3rem;
    }
    @include respond-below(md) {
      padding-bottom: 15px;
    }
  }
  &__social{
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    border: 2px solid $main-blue-color;
    &>i{
      padding: 9px 12px;
    }
  }
  &__contact{

  }
  &__qr{
    &>img{
      height: 120px;
      width: 120px;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__contact-item{
    margin-bottom: 4px;
    &:first-of-type{
      margin-bottom: 15px;
    }
  }
  &__partners{
    &>img{
      max-width: 100%;
    }
  }
}
