$news-item-height: 328px;
$news-item-height-md: 250px;
$news-item-width-md: 284px;
$news-item-height-sm: 110px;

$news-footer-quote-pos: -30px;
$news-footer-separator-pos: 0px;
$news-footer-type-pos: 3px;

$news-image-content-spacing-xs: 60px;
$news-image-content-spacing-sm: 60px;
$news-image-content-spacing-md: 70px;
$news-image-content-spacing-lg: 40px;
$news-image-content-spacing-xl: 40px;

.news {
  @include respond-below(md) {
    padding-left: 15px;
    padding-right: 15px;
  }
  &__item-col {
    padding-bottom: 0;
    @include respond-below(xl) {
      padding-bottom: $news-image-content-spacing-lg;
    }
    @include respond-below(lg) {
      padding-bottom: $news-image-content-spacing-md;
    }
    @include respond-below(md) {
      padding-bottom: $news-image-content-spacing-sm;
    }
    @include respond-below(sm) {
      padding-bottom: $news-image-content-spacing-xs;
    }
    &:nth-of-type(1n+4) {
    }
    &:nth-of-type(3n+1) {
      .news__item {
        padding-right: 7px;
        @include respond-below(xl) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
    &:nth-of-type(3n+2) {
      .news__item {
        padding-left: 8px;
        padding-right: 8px;
        @include respond-below(xl) {
          padding-right: 0;
          padding-left: 0;
        }
        .news__thumbnail-box {
          order: 2;
          margin-top: 0;
          @include respond-below(xl) {
            order: 1;
          }
        }

        .news__content-box {
          order: 1;
          margin-bottom: $news-image-content-spacing-xl;
          @include respond-below(xl) {
            margin-bottom: 0;
            order: 2;
          }
          @include respond-below(lg) {
            margin-bottom: 0;
          }
        }
      }
    }
    &:nth-of-type(3n+3) {
      .news__item {
        padding-left: 7px;
        @include respond-below(xl) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }

  &__item {
    padding: 0;
    display: flex;
    flex-flow: column;
    max-width: 380px;
    margin-right: auto;
    margin-left: auto;
    @include respond-below(lg) {
      flex-flow: row;
      padding: 0;
      max-width: 100%;
    }
  }

  &__item-link {
    text-decoration: none !important;
  }

  &__thumbnail-box {
    display: flex;
    flex-flow: column;
    min-height: $news-item-height;
    justify-content: flex-start;
    flex: 1 1 auto;
    margin-bottom: $news-image-content-spacing-xl;
    @include respond-below(xl) {
      margin-bottom: 10px;
    }
    @include respond-below(lg) {
      flex: 0 0 45%;
      margin-bottom: 0;
      min-height: $news-item-height-md;
      max-width: $news-item-width-md;
      margin-right: 15px;
    }
    @include respond-below(md) {
      flex: 0 0 auto;
      min-height: $news-item-height-sm;
      margin-right: 10px;
      align-self: flex-start;
      margin-bottom: 0;
      width: 130px;
      max-width: 130px;
      min-width: 130px;
    }
    @include respond-below(sm) {
      margin-bottom: 0;
    }
  }
  &__content-box {
    display: flex;
    flex-flow: column;
    min-height: $news-item-height;
    height: inherit;
    flex: 1 1 auto;
    @include respond-below(xl) {

    }
    @include respond-below(lg) {
      flex: 1 1 auto;
      min-height: 50px;
    }
    @include respond-below(md) {
    }
  }

  &__item-actual-image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex: 1 1 auto;
    display: block;
    width: 100%;
  }

  &__item-heading {
    flex: 0 0 auto;
    margin: 0 0 5px 0;
    & > h3 {
      margin: 0;
      word-break: break-word;
      @include respond-below(lg) {
        font-size: 20px;
      }
    }
    @include respond-below(lg) {
      margin: 0 0 15px;
    }
  }

  &__item-content {
    flex: 1 0 auto;
    overflow: hidden;
    p{
      margin: 0;
    }
  }

  &__item-more {
    color: #e30613;
    font-weight: 300;
  }

  &__item-footer {
    flex: 0 0 auto;
    display: block;
    position: relative;
    @include respond-below(lg) {
      position: absolute;
      left: 0;
      right: 0;
      padding: 0 15px;
      bottom: 45px;
    }
    @include respond-below(md) {
      bottom: 40px;
    }
  }
}

.news-footer {
  &__quote {
    display: inline-block;
    content: "";
    background-image: url("/uploads/resources/news-markpng.png");
    background-repeat: no-repeat;
    background-size: contain;
    bottom: $news-footer-quote-pos;
    position: absolute;
    width: 54px;
    height: 50px;
    @include respond-below(lg) {
      height: 40px;
      width: 40px;
      left: 0;
    }
  }

  &__news-type {
    font-size: 15px;
    font-weight: 300;
    color: #004976;
    position: absolute;
    bottom: $news-footer-type-pos;
    right: 0;
    @include respond-below(lg) {
      font-size: 14px;
    }
  }

  &__separator {
    display: inline-block;
    position: absolute;
    left: 74px;
    right: 0;
    bottom: $news-footer-separator-pos;
    height: 3px;
    background-color: #e30613;
    @include respond-below(xl) {
    }
    @include respond-below(lg) {
      height: 2px;
      left: 50px;
    }
  }
}

.news__item:hover{
  .news__content-box{
    .news__item-content{
      p{
        .news__item-more{
          font-weight: 800;
        }
      }
    }
  }
}
