.message{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 2px solid $main-dark-blue-color;
  padding: 30px 30px;
  position: relative;
  color: $main-blue-color;
  margin: auto;
  &__close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  &__header{
    text-align: center;
  }
  &__body{
    text-align: center;
  }
  &__footer{
    text-align: center;
  }

  &__text{
    &--heading{
      font-weight: 800;
      font-size: 18px;
    }
  }

  &--newsletter{
    border-color: $green-color;
    min-height: 150px;
    width: 600px;
    @include respond-below(lg) {
      width: 100%;
    }
  }
}