$scaling-factor: 1.26666;

$gallery-container-height-l: 400px * $scaling-factor;
$gallery-img-width-l: 600px * $scaling-factor;
$gallery-img-width-m: 500px * $scaling-factor;
$gallery-img-width-s: 400px * $scaling-factor;
$gallery-img-height-l: 380px * $scaling-factor;
$gallery-img-height-m: 320px * $scaling-factor;
$gallery-img-height-s: 260px * $scaling-factor;
$gallery-image-offset-m: 100px * $scaling-factor;
$gallery-image-offset-s: 200px * $scaling-factor;

$gallery-container-height-l-mobile-sm: 200px;
$gallery-img-width-l-mobile-sm: 70%;
$gallery-img-width-m-mobile-sm: 60%;
$gallery-img-width-s-mobile-sm: 50%;
$gallery-img-height-l-mobile-sm: 200px;
$gallery-img-height-m-mobile-sm: 150px;
$gallery-img-height-s-mobile-sm: 100px;
$gallery-image-offset-m-mobile-sm: 10%;
$gallery-image-offset-s-mobile-sm: 20%;

$gallery-container-height-l-mobile-md: 300px;
$gallery-img-width-l-mobile-md: 400px;
$gallery-img-width-m-mobile-md: 300px;
$gallery-img-width-s-mobile-md: 200px;
$gallery-img-height-l-mobile-md: 280px;
$gallery-img-height-m-mobile-md: 220px;
$gallery-img-height-s-mobile-md: 160px;
$gallery-image-offset-m-mobile-md: 70px;
$gallery-image-offset-s-mobile-md: 140px;

$gallery-container-height-l-mobile-lg: 400px;
$gallery-img-width-l-mobile-lg: 500px;
$gallery-img-width-m-mobile-lg: 400px;
$gallery-img-width-s-mobile-lg: 300px;
$gallery-img-height-l-mobile-lg: 330px;
$gallery-img-height-m-mobile-lg: 270px;
$gallery-img-height-s-mobile-lg: 210px;
$gallery-image-offset-m-mobile-lg: 80px;
$gallery-image-offset-s-mobile-lg: 160px;

.gallery {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  &__header-label {
    font-size: 22px;
    font-weight: 700;
    color: #004976;
  }
  &__title-label {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 15px;
    color: black;
    padding-bottom: 2rem;
  }
  &__controls-container{
    display: flex;
    position: absolute;
    align-items: center;
    z-index: 35;
    width: 100%;
    height: 0;
  }
  &__controls-item{
    position: absolute;
    display: inline-block;
    flex: 0 0 auto;
    cursor: pointer;
    &:first-of-type{
      left: 0;
      padding: 15px 15px 15px 0;
    }
    &:last-of-type{
      right: 0;
      padding: 15px 0 15px 15px;
    }
  }
  &--slide-view {
    height: $gallery-container-height-l;
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    flex-wrap: nowrap;
    position: relative;
    @include respond-below(lg) {
      height: $gallery-container-height-l-mobile-lg;
    }
    @include respond-below(md) {
      height: $gallery-container-height-l-mobile-md;
    }
    @include respond-below(sm) {
      height: $gallery-container-height-l-mobile-sm;
      margin-bottom: 15px;
    }
    & .image-box {
      transition: all 0.2s ease-out;
      flex: 0 0 auto;
      z-index: 30;
      position: absolute;
      display: block;
      padding: 0;
      opacity: 0;
      height: 0;
      width: 0;
      &.previous-far {
        z-index: 30;
        opacity: 1;
        margin-left: -$gallery-image-offset-s;
        width: $gallery-img-width-s;
        max-width: $gallery-img-width-s;
        height: $gallery-img-height-s;
        max-height: $gallery-img-height-s;
        @include respond-below(lg) {
          margin-left: -$gallery-image-offset-s-mobile-lg;
          width: $gallery-img-width-s-mobile-lg;
          max-width: $gallery-img-width-s-mobile-lg;
          height: $gallery-img-height-s-mobile-lg;
          max-height: $gallery-img-height-s-mobile-lg;
        }
        @include respond-below(md) {
          margin-left: -$gallery-image-offset-s-mobile-md;
          width: $gallery-img-width-s-mobile-md;
          max-width: $gallery-img-width-s-mobile-md;
          height: $gallery-img-height-s-mobile-md;
          max-height: $gallery-img-height-s-mobile-md;
        }
        @include respond-below(sm) {
          margin-left: -$gallery-image-offset-s-mobile-sm;
          width: $gallery-img-width-s-mobile-sm;
          max-width: $gallery-img-width-s-mobile-sm;
          height: $gallery-img-height-s-mobile-sm;
          max-height: $gallery-img-height-s-mobile-sm;
        }
      }

      &.next-far {
        z-index: 30;
        opacity: 1;
        margin-left: $gallery-image-offset-s;
        width: $gallery-img-width-s;
        max-width: $gallery-img-width-s;
        height: $gallery-img-height-s;
        max-height: $gallery-img-height-s;
        @include respond-below(lg) {
          margin-left: $gallery-image-offset-s-mobile-lg;
          width: $gallery-img-width-s-mobile-lg;
          max-width: $gallery-img-width-s-mobile-lg;
          height: $gallery-img-height-s-mobile-lg;
          max-height: $gallery-img-height-s-mobile-lg;
        }
        @include respond-below(md) {
          margin-left: $gallery-image-offset-s-mobile-md;
          width: $gallery-img-width-s-mobile-md;
          max-width: $gallery-img-width-s-mobile-md;
          height: $gallery-img-height-s-mobile-md;
          max-height: $gallery-img-height-s-mobile-md;
        }
        @include respond-below(sm) {
          margin-left: $gallery-image-offset-s-mobile-sm;
          width: $gallery-img-width-s-mobile-sm;
          max-width: $gallery-img-width-s-mobile-sm;
          height: $gallery-img-height-s-mobile-sm;
          max-height: $gallery-img-height-s-mobile-sm;
        }
      }

      &.previous {
        z-index: 31;
        opacity: 1;
        margin-left: -$gallery-image-offset-m;
        width: $gallery-img-width-m;
        max-width: $gallery-img-width-m;
        height: $gallery-img-height-m;
        max-height: $gallery-img-height-m;
        @include respond-below(lg) {
          margin-left: -$gallery-image-offset-m-mobile-lg;
          width: $gallery-img-width-m-mobile-lg;
          max-width: $gallery-img-width-m-mobile-lg;
          height: $gallery-img-height-m-mobile-lg;
          max-height: $gallery-img-height-m-mobile-lg;
        }
        @include respond-below(md) {
          margin-left: -$gallery-image-offset-m-mobile-md;
          width: $gallery-img-width-m-mobile-md;
          max-width: $gallery-img-width-m-mobile-md;
          height: $gallery-img-height-m-mobile-md;
          max-height: $gallery-img-height-m-mobile-md;
        }
        @include respond-below(sm) {
          margin-left: -$gallery-image-offset-m-mobile-sm;
          width: $gallery-img-width-m-mobile-sm;
          max-width: $gallery-img-width-m-mobile-sm;
          height: $gallery-img-height-m-mobile-sm;
          max-height: $gallery-img-height-m-mobile-sm;
        }
      }

      &.next {
        z-index: 31;
        opacity: 1;
        margin-left: $gallery-image-offset-m;
        width: $gallery-img-width-m;
        max-width: $gallery-img-width-m;
        height: $gallery-img-height-m;
        max-height: $gallery-img-height-m;
        @include respond-below(lg) {
          margin-left: $gallery-image-offset-m-mobile-lg;
          width: $gallery-img-width-m-mobile-lg;
          max-width: $gallery-img-width-m-mobile-lg;
          height: $gallery-img-height-m-mobile-lg;
          max-height: $gallery-img-height-m-mobile-lg;
        }
        @include respond-below(md) {
          margin-left: $gallery-image-offset-m-mobile-md;
          width: $gallery-img-width-m-mobile-md;
          max-width: $gallery-img-width-m-mobile-md;
          height: $gallery-img-height-m-mobile-md;
          max-height: $gallery-img-height-m-mobile-md;
        }
        @include respond-below(sm) {
          margin-left: $gallery-image-offset-m-mobile-sm;
          width: $gallery-img-width-m-mobile-sm;
          max-width: $gallery-img-width-m-mobile-sm;
          height: $gallery-img-height-m-mobile-sm;
          max-height: $gallery-img-height-m-mobile-sm;
        }
      }

      &.current {
        z-index: 32;
        opacity: 1;
        width: $gallery-img-width-l;
        max-width: $gallery-img-width-l;
        height: $gallery-img-height-l;
        max-height: $gallery-img-height-l;
        @include respond-below(lg) {
          width: $gallery-img-width-l-mobile-lg;
          max-width: $gallery-img-width-l-mobile-lg;
          height: $gallery-img-height-l-mobile-lg;
          max-height: $gallery-img-height-l-mobile-lg;
        }
        @include respond-below(md) {
          width: $gallery-img-width-l-mobile-md;
          max-width: $gallery-img-width-l-mobile-md;
          height: $gallery-img-height-l-mobile-md;
          max-height: $gallery-img-height-l-mobile-md;
        }
        @include respond-below(sm) {
          width: $gallery-img-width-l-mobile-sm;
          max-width: $gallery-img-width-l-mobile-sm;
          height: $gallery-img-height-l-mobile-sm;
          max-height: $gallery-img-height-l-mobile-sm;
        }
      }
      img {
        width: auto;
        height: 100%;
        box-shadow: 1px 0 5px 1px rgba(9, 1, 2, 0.35);
      }
    }

    .glightbox {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
    }

    .glide-image {
      max-width: 100%;
      flex: 0 0 auto;
      min-height: 100%;
      display: block;
      align-self: center;
      @include respond-below(sm) {
        min-width: 100%;
        max-width: none;
      }
    }
  }
}

@-moz-document url-prefix() {
  .gallery--slide-view .image-box.previous-far {
    margin-left: -2 * $gallery-image-offset-s;
    @include respond-below(lg) {
      margin-left: -2 * $gallery-image-offset-s-mobile-lg;
    }
    @include respond-below(md) {
      margin-left: -2 * $gallery-image-offset-s-mobile-md;
    }
    @include respond-below(sm) {
      margin-left: -2 * $gallery-image-offset-s-mobile-sm;
    }
  }

  .gallery--slide-view .image-box.next-far {
    margin-left: 2 * $gallery-image-offset-s;
    @include respond-below(lg) {
      margin-left: 2 * $gallery-image-offset-s-mobile-lg;
    }
    @include respond-below(md) {
      margin-left: 2 * $gallery-image-offset-s-mobile-md;
    }
    @include respond-below(sm) {
      margin-left: 2 * $gallery-image-offset-s-mobile-sm;
    }
  }

  .gallery--slide-view .image-box.previous {
    margin-left: -2 * $gallery-image-offset-m;
    @include respond-below(lg) {
      margin-left: -2 * $gallery-image-offset-m-mobile-lg;
    }
    @include respond-below(md) {
      margin-left: -2 * $gallery-image-offset-m-mobile-md;
    }
    @include respond-below(sm) {
      margin-left: -2 * $gallery-image-offset-m-mobile-sm;
    }
  }

  .gallery--slide-view .image-box.next {
    margin-left: 2 * $gallery-image-offset-m;
    @include respond-below(lg) {
      margin-left: 2 * $gallery-image-offset-m-mobile-lg;
    }
    @include respond-below(md) {
      margin-left: 2 * $gallery-image-offset-m-mobile-md;
    }
    @include respond-below(sm) {
      margin-left: 2 * $gallery-image-offset-m-mobile-sm;
    }
  }
}
