$main-blue-color: #004976;
$main-dark-blue-color: #003c67;
$main-light-blue-color: #D8EDFF;
$grey-color: #E6E6E6;
$red-color: #e30613;
$white-color: #ffffff;
$green-color: #d8edff;

//header top-menu dropdown offset
$top-nav-dropdown-top: 31px;
$top-nav-dropdown-top--sm: 26px;
$top-nav-dropdown-left: 13px;
$header-height: 85px;
$header-height-mobile: 155px;

//banner glider controls and bullets
$glider-controls-padding: 30px;

//bootstrap breakpoints
$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

//mixin for bootstrap breakpoints
@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin respond-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
