body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif, Arial, Verdana, "Trebuchet MS";
  font-weight: 300;
}

*, *::before, *::after {
  font-family: inherit;
  font-weight: inherit;
}

.hidden {
  display: none;
}

// IE 11 detection + row width workaround
_:-ms-fullscreen, :root .w-100-ie11 {
  width: 100% !important;
}

_:-ms-fullscreen, :root .red-button--shrink {
  display: inline-block;
  line-height: 38px;
}

_:-ms-fullscreen, :root .attr-box__col {
  display: block;
}

// Cookie consent overrides
.cc-compliance {
  border: 1px white solid;
}

.cc-btn {
  font-weight: normal !important;
}

.cc-btn:hover {
  text-decoration: none;
  background-color: #004976 !important;
}

// hiding the titles in glightbox - we want img alts but not to show them :)
.gslide-title, .gslide-description {
  display: none;
}

// menu
.site-header .dropdown-menu {
  opacity: 0.8;
}

// banner slider
.page-banner {
  background: no-repeat center;
  height: 618px;
  @include respond-below(xl) {
    height: 520px;
  }
  @include respond-below(lg) {
    height: 390px;
  }
  @include respond-below(md) {
    height: 293px;
  }
  @include respond-below(sm) {
    height: 172px;
  }
}

.glide {
  position: relative;
  height: 100%;
  &__heading{
    color: $main-blue-color;
    background-color: rgba(#fff, 0.7);
    display: inline-block;
    padding: 0;
    border-right: 4px solid red;
    border-left: 4px solid red;
    text-transform: uppercase;
    font-size: 35px;
  }
  &__heading-box{
    padding-top: 30px;
  }
  &__bullets-container{
    position: absolute;
    //bottom offset 22px + normal spacing from design
    bottom: calc( 22px + 12px );
    height: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-end;
    @include respond-below(md) {
      display: none;
    }
  }
  &__bullets-item{
    flex: 0 0 auto;
    margin: 5.5px;
    height: 10px;
    width: 10px;
    display: inline-block;
    transition: background-image 0.4s ease-in-out;
    -webkit-transition: background-image 0.4s ease-in-out;
    background-image: url("/bundles/app/images/slider_bullet.png");
    &--active{
      background-image: url("/bundles/app/images/slider_bullet_active.png");
    }
  }
  &__controls-container{
    position: absolute;
    top: 50%;
    height: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__controls-item{
    position: absolute;
    padding: $glider-controls-padding 0;
    cursor: pointer;
    &:first-of-type{
      left: 0;
      padding-right: $glider-controls-padding;
    }
    &:last-of-type{
      right: 0;
      padding-left: $glider-controls-padding;
    }
  }
  &__controls-img{
  }
  &__slides{
    margin-bottom: 0;
  }
  &__slide {
    & > a {
      display: block;
      height: 618px;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include respond-below(xl) {
        height: 520px;
      }
      @include respond-below(lg) {
        height: 390px;
      }
      @include respond-below(md) {
        height: 293px;
      }
      @include respond-below(sm) {
        height: 172px;
      }
      &:hover{
        text-decoration: none;
      }
      & h1{
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
}

// end banner slider

// units_filter_form
.units_filter {
  display: flex;
  align-items: center;
  font-size: 15px;
  &__grid-tooltip{
    margin: 30px 0 15px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: $main-blue-color;
    line-height: 2;
    &>img{
      margin-left: 15px;
      margin-right: 5px;
      &:first-of-type{
        margin-left: 0;
      }
    }
  }
  &__col{
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    align-content: center;
    align-items: center;
    padding: 0 16px 0 0;
    &:last-of-type{
      padding-right: 0;
    }
    @include respond-below(lg) {
      margin-top: 15px;
      padding-left: 8px;
      padding-right: 8px;
      &:last-of-type{
        padding-right: 8px;
      }
    }
    @include respond-below(sm) {
      padding: 0;
      &:last-of-type{
        padding-right: 0;
      }
    }
    &--sorter{
      flex: 0 1 auto;
      width: 200px;
      &:first-of-type{
        padding-left: 0;
      }
      &:last-of-type{
        padding-right: 0;
      }
      @include respond-below(lg) {
        width: 50%;
        padding-right: 8px;
        padding-left: 8px;
      }
      @include respond-below(md) {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }
    &>input{
      flex: 1 1 auto;
    }
    &>input[type="checkbox"]{
      flex: 0 1 auto;
    }
    &>label{
      flex: 1 1 auto;
      margin: 0;
    }
  }
  &__submit-col{
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    align-content: center;
    align-items: center;
    padding: 0 0 0 38px;
    @include respond-below(lg) {
      margin-top: 15px;
      padding-left: 8px;
      padding-right: 8px;
    }
    @include respond-below(sm) {
      padding: 0;
    }
  }
  &__input-row {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    min-height: 71px;
    width: 100%;
    padding: 25px 0;
    margin-bottom: 0;
    @include respond-below(sm) {
      padding-bottom: 15px;
    }
    @include respond-below(md) {
      padding-left: 8px;
      padding-right: 8px;
    }
    &--blue{
      padding: 15px 12px;
      background-color: $main-light-blue-color;
    }
    &--stack-right{
      justify-content: flex-end;
    }
  }
  &__icon-dropdown{
    &:before{
      content: "";
      display: inline-block;
      transition: transform 0.2s ease-in-out;
      -webkit-transition: transform 0.2s ease-in-out;
      width: 20px;
      height: 20px;
      background: url("/bundles/app/images/arrow_down.png") no-repeat center;
      background-size: contain;
    }
  }
  &__item {
    height: 40px;
    color: #000000;
    background-color: $white-color;
    width: 100%;
    flex: 1 1 auto;

    &--bordered {
      border: 1px solid $main-light-blue-color;
      background-color: #ffffff;
      & .dropdown .units_filter__dropdown{
        margin-top: -4px;
      }
    }
  }

  &__select-box {
    padding: 9px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    cursor: pointer;

    &__label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    i {
      color: $red-color;
    }
  }

  &__button {
    flex: 1 1 auto;
    width: 100%;
    height: 40px;
    button {
      width: 100%;
      height: 100%;
      color: $white-color;
      font-size: 15px;
    }
  }

  &__dropdown {
    min-width: 100%;
    border: none;
    border-radius: 0;
    margin-top: -2px;
    box-sizing: border-box;
    transform: translate(0, 42px) !important;
    color: $main-blue-color;
    font-size: 15px;
    padding: 0;
    ul {
      margin-bottom: 0;
    }

    li {
      padding: 5px 15px;
      font-size: 15px;
      &:hover {
        cursor: pointer;
        background-color: $main-dark-blue-color;
        color: #fff;
      }
    }

    &--blue{
      background-color: $main-light-blue-color;
      width: calc(100% + 2px);
      transform: translate(-1px, 41px) !important;
      li{
        color: #000;
        &:hover{
          background-color: #fff;
          color: #000;
        }
      }
    }
    &.show {
    }
  }
}

.dropdown{
  &.show{
    & .units_filter__select-box .units_filter__icon-dropdown{
      &:before{
        transform: rotate(180deg);
      }
    }
  }
}

// end units_filter_form

// units_filter table
table.units {
  thead {
    background-color: #004976;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;

    a {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }

    th {
      border-bottom: 1px solid #467c9e;
    }
  }

  tbody {
    color: #004976;
    font-size: 14px;
    font-weight: 300;

    tr {
      &:nth-of-type(odd) {
        background-color: $main-light-blue-color !important;
      }
    }

    td {
      border-top: 0;
    }
  }
}

// end units_filter table

// end units grid-view

// newsletter-box
.newsletter{
  &__message
  {
    width: 100%;
  }
}
.newsletter-box {
  display: flex;
  align-items: center;
  background-color: $grey-color;
  padding: 16px 0 14px;
  margin-top: 40px;
  margin-bottom: 16px;

  &__content {
    font-size: 15px;
    line-height: 18px;

    color: #004976;

    @include respond-below(md) {
      margin-bottom: 10px;
    }
  }

  &__form {
    height: 43px;
    @include respond-below(md) {
      height: auto;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      @include respond-below(md) {
        flex-flow: column;
        align-items: center;
      }
    }
  }

  &__input {
    max-width: 352px;
    width: 100%;
    &>input{
      border-radius: 0;
    }
    @include respond-below(md) {
      flex: 0 0;
      width: 100%;
      max-width: initial;
      height: 43px;
    }
    &>input::-webkit-input-placeholder {
      font-style: italic;
    }
    &>input:-moz-placeholder {
      font-style: italic;
    }
    &>input::-moz-placeholder {
      font-style: italic;
    }
    &>input:-ms-input-placeholder {
      font-style: italic;
    }
  }

  &__button {
    max-width: 160px;
    width: 100%;
    background-color: $grey-color;
    text-align: center;
    margin: 0 15px;
    @include respond-below(md) {
      flex: 0 0;
      width: 100%;
      height: 43px;
      margin: 15px 15px 5px 15px;
    }
  }

  input,
  button {
    color: $main-blue-color;
    height: 100%;
    width: 100%;
  }

  button {
    font-weight: 300;
    text-transform: uppercase;
    border: 1px solid $main-blue-color;
    border-radius: 0;
  }
}

// end newsletter-box


.list-group-image {
  width: 100%;
  max-width: 350px;
  max-height: 330px;
}

.tile {
  .tile-label {
    position: absolute;
    right: 0;
    width: 100px;
    min-height: 20px;
    padding: 5px 10px;
  }

  .card-body {
    min-height: 200px;
  }
}

// buttons
.main-button {
  height: 40px;
  color: $main-blue-color;
  background-color: $white-color;
  border-radius: initial;
  border: 1px solid $main-blue-color;
}

.red-button {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 300;
  min-height: 40px;
  padding: 0 12px;
  background-color: $red-color !important;
  border-radius: initial;
  text-transform: uppercase;
  color: #ffffff !important;
  @include respond-below(sm) {
    min-height: 35px;
    font-size: 13px;
    padding: 0 7px;
  }
  &:hover{
    color: #ffffff !important;
    background-color: #d20815 !important;
  }
  &--shrink{
    max-width: fit-content;
  }
  &--expand{
    width: 100%;
  }
}

// gallery
.gallery {
  .modal-dialog {
    max-width: min-content;
  }

  .gallery-box {
    width: 100%;
    //display: flex;
  }

  .image-box {
    padding: 5px;
    //&:nth-of-type(7n){
    //  padding-left: 0;
    //}
    img {
      width: 100%;
    }
  }
}

.swiper-wrapper {
  height: initial;
}

.swiper-container {
  width: 100%;
  padding: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  //max-width: 761px;
  //width: 100%;
  //height: 480px;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: red;
  background: rgba(255, 255, 255, .8);
}


.bg-news-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.news-block {
  &--item {
    width: 100%;
    max-width: 359px;
    height: 212px;

    margin-top: 15px;
    margin-bottom: 15px;
  }

  &--grey-background {
    height: 100%;
    background-color: #BFBFBF;
  }

  &__content {

  }

  &__tile {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

hr.red-line {
  height: 3px;
  background-color: #e30613;
}

hr.subpages-divider {
  margin-top: 13px;
  margin-bottom: 60px;
}

// navigation tabs
.subpages {
  .nav-tabs {
    border-bottom: none;
    @include respond-above(md) {
      margin-left: -15px;
      margin-right: -15px;
    }
    @include respond-below(md) {
      margin-top: 10px;
    }
    .nav-item {
      margin-left: 0;
      margin-right: 20px;

      @include respond-below(md) {
        margin-left: 0;
        margin-right: 10px;
      }

      &:last-of-type {
        margin-right: 0;
        @include respond-below(lg) {
          margin-right: 0;
        }
        @include respond-below(md) {
          margin-right: 0;
        }
      }

      &:first-of-type {
        margin-left: 0;
        @include respond-below(lg) {
          margin-left: 0;
        }
        @include respond-below(md) {
          margin-left: 0;
        }
      }
    }

    .nav-link {
      border: none;
      padding: 0;
      display: inline-block;
      font-size: 17px;
      font-weight: 700;
      line-height: 17px;
      color: #004976;
      @include respond-below(lg) {
        font-size: 14px;
      }
      @include respond-below(md) {
        font-size: 13px;
      };
      &.active {
        color: #e30613;
        border-left: 3px solid red;
        text-decoration: underline;
        border-radius: 0;
        padding: 0 0 0 5px;
        @include respond-below(md) {
          border-width: 2px;
        }

        &:after {
          display: inline-block;
          content: '';
          background-image: url("/bundles/app/images/red_arrow_right_sm.png");
          width: 10px;
          height: 18px;
          margin-left: 6px;
          vertical-align: middle;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          @include respond-below(md) {
            width: 7px;
            height: 11px;
            margin-left: 2px;
          }
        }
      }
    }
  }
}

//we need to differentiate between ckeditor content on page and normal themed text
// used to display same page width on admin ckeditor
// as we have on frontpages
.cke_editable {
  @include make-container();
  @include make-container-max-widths();
}

.richtext{
  margin-right: -15px;
  margin-left: -15px;
}

.richtext,
.theme-styled-text,
.cke_editable {
  color: #004976;
  h1, h2 {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: -0.6px;
    color: #004976;
    margin-bottom: 16px;
    a {
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: -0.6px;
      color: #004976;
      @include respond-below(md) {
        font-size: 22px;
      }
    }
    @include respond-below(md) {
      font-size: 22px;
    }
    & .title-with-arrow {
      margin-left: 15px;
    }
    &:before {
      display: inline-block;
      content: "";
      background-image: url("/bundles/app/images/red_arrow_right_lg.png");
      background-repeat: no-repeat;
      width: 16px;
      height: 30px;
      margin-top: 2px;
      margin-right: 13px;
      vertical-align: middle;
      background-size: contain;
      @include respond-below(md) {
        width: 10px !important;
        height: 26px !important;
        margin-top: 3px;
      }
    }
  }

  h3 {
    font-size: 22px;
    font-weight: 700;
    color: #004976;
    margin-bottom: 1rem;
    a {
      font-size: 22px;
      font-weight: 700;
      color: #004976;
      margin-bottom: 1rem;
      @include respond-below(md) {
        font-size: 16px;
      }
    }
    @include respond-below(md) {
      font-size: 16px;
    }
  }

  p {
    font-size: 15px;
    line-height: 1.25;
    font-weight: 300;
    color: #004976;
    overflow-wrap: break-word;
    width: 100%;
    @include respond-below(sm) {
      line-height: 1.1rem;
      font-size: 13px;
    }
  }

  ul {
    font-size: 15px;
    font-weight: 300;
    color: #004976;
    list-style-image: url("/bundles/app/images/red_arrow_right_xs.png");
    @include respond-below(md) {
      font-size: 13px;
    }
  }

  .left-aligned {
    padding-inline-start: 15px;
  }

  .ul-header-red {
    color: #e30613;
    padding-left: 15px;
  }

  a {
    font-size: 15px;
    font-weight: 300;
    color: #e30613;
    @include respond-below(md) {
      font-size: 13px;
    }
  }
  a:hover {
    font-weight: 700;
    text-decoration: none;
  }

  b,
  strong {
    font-weight: 700;
  }
  img{
    max-width: 100%;
  }
}

.pt-40 {
  padding-top: 40px;
}

.phone-icon {
  &:before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url("/bundles/app/images/phone_icon_kopie_3.png");
    margin-right: 9px;
    vertical-align: middle;
  }
}

.email-icon {
  &:before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 18px;
    background-image: url("/bundles/app/images/mail_icon_kopie_3.png");
    margin-right: 9px;
    vertical-align: middle;
  }
}

.quotation-block {
  position: relative;
  padding-bottom: 64px;

  .quotation-block-footer {
    font-size: 15px;
    font-weight: 300;
    color: #004976;
    position: absolute;
    bottom: 38px;
    right: 0;
  }

  &:before {
    display: inline-block;
    position: absolute;
    content: "";
    background-image: url("/uploads/resources/news-markpng.png");
    background-repeat: no-repeat;
    bottom: 0;
    width: 54px;
    height: 50px;
  }

  &:after {
    display: inline-block;
    position: absolute;
    content: "";
    left: 74px;
    right: 0;
    bottom: 29px;
    height: 3px;
    background-color: #e30613;
  }
}

.title-with-arrow {
  display: flex;
  flex-wrap: nowrap;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.6px;
  color: #004976;

  &:before {
    display: inline-block;
    content: "";
    background-image: url("/bundles/app/images/red_arrow_right_lg.png");
    background-repeat: no-repeat;
    width: 16px;
    height: 26px;
    margin-right: 14px;
    margin-top: 5px;
    vertical-align: middle;
    background-size: contain;
  }
}

.check-box{
  flex: 0 0 auto;
  display: inline-block;
  height: 25px;
  width: 25px;
  background-color: #fff;
  margin-right: 16px;
  &__label{
    display: flex;
    align-items: center;
  }
  &--checked{
    display: flex;
    align-items: center;
    justify-content: center;
    &:before{
      color: #e30613;
      display: inline-block;
      flex: 0 1 auto;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 18px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\f00c";
    }
  }
}

a.downloads {
  color: #004976;
  &:hover {
    color: #004976;
    text-decoration: none;
    font-weight: 300;
  }
}

.speech-bubble-tl-blue:after {
  content: '';
  background-image: url('/uploads/images/zobacek_modry_TL.png');
  position: absolute;
  margin-top: -50px;
  width: 88px;
  height: 38px;
}

.speech-bubble-bl-blue:after {
  content: '';
  background-image: url('/uploads/images/zobacek_modry_BL.png');
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -41px;
  width: 116px;
  height: 41px;
}

.speech-bubble-br-blue:after {
  content: '';
  background-image: url('/uploads/images/zobacek_modry_BR.png');
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: -41px;
  width: 126px;
  height: 41px;
}

.speech-bubble-bl-white:after {
  content: '';
  background-image: url('/uploads/images/zobacek_bily_BL.png');
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -33px;
  width: 77px;
  height: 33px;
}

.speech-bubble-br-white:after {
  content: '';
  background-image: url('/uploads/images/zobacek_bily_BR.png');
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: -33px;
  width: 77px;
  height: 33px;
}

.speech-bubble-tr-white:after {
  content: '';
  background-image: url('/uploads/images/zobacek_bily_TR.png');
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -33px;
  width: 77px;
  height: 33px;
}

// stop showing phone number in a highlighted manner on mobile Safari
a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}
