@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "~@glidejs/glide/src/assets/sass/glide.core";
@import "~glightbox/dist/css/glightbox.min.css";
@import "~swiper/css/swiper.min.css";
@import url('~font-awesome/css/font-awesome.min.css');
@import 'storefront/variables';
@import "storefront/navbar";
@import "storefront/project";
@import "storefront/unit";
@import "storefront/general";
@import "storefront/footer";
@import "storefront/messages";
@import "storefront/breadcrumbs";
@import "storefront/gallery";
@import "storefront/news";
@import "storefront/components/homepage";
@import "storefront/components/about-us";
