.site-header {
  color: $main-blue-color;
  height: $header-height;
  @include respond-below(md) {
    flex-direction: row;
    height: $header-height-mobile;
  }

  &__contact-link {
    @include respond-below(md) {
      padding-left: 15px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__contact-text {
    font-size: 18px;
    @include respond-below(xl) {
      font-size: 16px;
    }
    @include respond-below(md) {
      display: none;
    }
  }

  &__navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 12px 13px;
    @include respond-below(md) {
      flex-direction: column;
    }
  }

  .container {
    background: $main-light-blue-color;
  }

  &__logo-link {
    display: block;
    flex: 0 1 auto;
  }

  &__logo {
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    @include respond-below(md) {
      height: 84px;
    }

    & > a {
      & > img {
        max-height: 100%;
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    @include respond-below(md) {
      width: 30px;
      height: 30px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex: auto;
    margin-left: 25px;
    font-size: 20px;
    font-weight: 400;
    @include respond-below(md) {
      flex-wrap: wrap;
      margin-left: 0;
    }
  }

  &__item {
    flex: 0 0 auto;
    white-space: nowrap;
    margin-right: 15px;

    &--fill {
      flex: 1 1 auto;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &--contact {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 18px;

      @include respond-below(md) {
        justify-content: flex-start;
        gap: 10px;
      }
    }

    @include respond-above(lg) {
      margin-right: 40px;
    }
    @include respond-below(md) {
      margin-right: 10px;
    }
  }

  .dropdown {
    @include respond-below(md) {
      position: initial;
    }
    &-menu {
      min-width: 300px;
      text-align: center;
      padding: 0;
      margin: 0;
      left: $top-nav-dropdown-left !important;
      top: $top_nav-dropdown-top !important;
      border: none;
      border-radius: initial;
      @include respond-below(md) {
        top: $header-height-mobile !important;
        left: 0 !important;
        right: 0 !important;
        position: absolute !important;
        transform: none !important;
      }

      ul {
        margin-bottom: 0;
        color: $main-blue-color;

        li {
          &:hover {
            background-color: $main-blue-color;
            color: #fff;

            & > a {
              color: #fff;
              &:before{
                display: inline-block;
                content: "";
                background-image: url("/bundles/app/images/red_arrow_right_lg.png");
                background-repeat: no-repeat;
                width: 16px;
                height: 23px;
                margin-right: 10px;
                vertical-align: middle;
                background-size: contain;
              }
            }
          }

          &:active {
            background-color: $main-dark-blue-color;
            font-weight: 800;
            & > a {
              color: #fff;
            }
          }

          a {
            color: $main-blue-color;
            font-size: 18px;
            padding: 11.5px 0;
            width: 100%;
            height: 100%;
            display: block;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  &__language-picker {
    align-items: center;
    flex-flow: column;
  }
}

.language-picker {
  &__list {
    text-align: center;
    flex: 0 0 auto;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  &__item {
    flex: 0 0 auto;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    border-right: 2px solid $main-blue-color;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__item:last-of-type {
    border-right: none;
    padding-right: 0;
  }

  &__link {
    padding: 10px;
    color: $main-blue-color;

    &:hover, :active, :focus {
      color: $main-blue-color;
      font-weight: 800;
      text-decoration: none;
    }
  }
}

.top-menu {
  display: flex;
  flex-flow: row;
  align-items: center;
  @include respond-below(md) {
    padding-right: 15px;
  }

  &__hamburger {
  }

  &__text {
    margin-right: 12px;
    text-transform: uppercase;
    @include respond-below(lg) {
      display: none;
    }
  }
}

.hamburger {
  &__icon {

  }
}
