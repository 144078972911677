/* Homepage */
.homepage {
  h1, h2 {
    padding-top: 40px;
  }

  h3 {
    font-size: 22px;
    font-weight: 700;
    color: #004976;
  }

  p {
    font-size: 15px;
    font-weight: 300;
    color: #004976;
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  .thirds {
    @include respond-above(md) {
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  .homepage-showcase__more-info {
    width: 100%;
    display: block;
  }

  &.homepage-showcase {
    padding: 0 12px;

    p {
      line-height: 18px;
      margin-bottom: 0;
    }

    img {
      position: relative;
      left: -4px;
      width: calc(100% + 4px);
    }
  }

  &.homepage-projects {
    img {
      position: relative;
      max-width: initial;
      @include respond-below(md) {
        left: 0;
      }
    }

    p {
      margin-bottom: 0;
      margin-top: 0;
    }

    .text-right {
      a {
        &:after {
          display: inline-block;
          content: "";
          background-image: url("/uploads/resources/link-arrowpng.png");
          background-repeat: no-repeat;
          width: 10px;
          height: 20px;
          margin-left: 5px;
          vertical-align: middle;
        }
      }
    }
  }
}

.homepage-about {
  p{
    &:last-of-type{
      margin-bottom: 20px;
    }
  }
}

.homepage-projects {
  &__item-group {
    padding-bottom: 0;
    @include respond-above(md) {
      padding-bottom: 22px;
      &:last-of-type {
        padding-bottom: 30px;
      }
    }
  }
  &__item {
    padding: 0;
    &:nth-of-type(odd) {
      @include respond-above(md) {
        padding-right: 12px;
      }
    }
    &:nth-of-type(even) {
      @include respond-above(md) {
        padding-left: 12px;
      }
    }
  }
}

