.page-banner {
  position: relative;

  &:after {
    content: "";
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}

iframe.w-100 {
  border: none;
}

.btn--vr-space{
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 37.5px;
  width: 37.5px;
  display: flex;
  background-color: #348CC5;
  align-items: center;
  justify-content: center;
  i{
    flex: 0 0 auto;
    color: #fff;
    font-size: 18px;
    //icon adjustment
    margin-top: 1px;
    margin-left: 4px;
  }
}

.image-map__container{
  position: relative;
}