/* About us */
.about-us {
  &.about-us_for-media {
    .about-us_for-media-item {
      margin-bottom: 2rem;
      a {
        font-weight: 700;
      }
    }
    .about-us_for-media-pagination {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: end;
      height: 100%;
      span {
        font-size: 15px;
        font-weight: 300;
        color: #004976;
        .previous {
          display: inline-block;
          content: "";
          background-image: url("/uploads/resources/link-arrowpng.png");
          background-repeat: no-repeat;
          width: 10px;
          height: 20px;
          margin-left: 5px;
          vertical-align: middle;
          transform: rotate(180deg);
        }
        .next {
          display: inline-block;
          content: "";
          background-image: url("/uploads/resources/link-arrowpng.png");
          background-repeat: no-repeat;
          width: 10px;
          height: 20px;
          margin-left: 5px;
          vertical-align: middle;
        }
      }
    }
  }
  &.about-us_financing {
    h3 {
      margin-top: 0.5rem;
    }
    ul {
      padding-left: 1rem;
    }
    .about-us_financing-ol {
      counter-reset: financing-counter;
      padding-left: 0;
      li {
        list-style: none;
        counter-increment: financing-counter;
        position: relative;
        z-index: 0;
        &:before {
          content: counter(financing-counter);
          position: absolute;
          font-size: 64px;
          font-weight: 700;
          line-height: 1;
          color: $main-light-blue-color;
          top: 0;
          left: 0;
          z-index: -1;
        }
        h3 {
          padding-top: 20px;
          margin-left: 40px;
        }
      }
    }
    .about-us_financing-bluebox {
      background-color: $main-light-blue-color;
      padding: 0.5rem;
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      h3 {
        margin-top: 0;
      }
      ul {
        padding-left: 1rem;
      }
      hr {
        border-top: 2px solid #e30613;
      }
    }
  }
  &.about-us_gdpr {
    h3 {
      margin-top: 2rem;
      &:first-of-type {
        margin-top: 0rem;
      }
    }
    .about-us_gdpr-ul {
      list-style-image: none;
      padding-left: 0;
      @include respond-below(md) {
        font-size: 13px;
      }
      li {
        list-style: none;
        padding-left: 1rem;
        &:before {
          content: "\2022";
          display: inline-block;
          font-weight: 700;
          color: #e30613;
          width: 1rem;
          margin-left: -1rem;
        }
      }
    }
  }
}

.career-item{
  &__position{
    position: absolute;
    flex: 1 1 auto;
    right: 57px;
    top: 8px;
    font-size: 22px;
    font-weight: 800;
    color: #6698c8;
    @include respond-below(lg) {
      right: 47px;
    }
  }
  &__sub-position{
    position: absolute;
    right: 25px;
    top: 28px;
    font-size: 22px;
    font-weight: 800;
    color: $main-blue-color;
    @include respond-below(lg) {
      right: 15px;
    }
  }
  &>a {
    height: auto;
    display: block;
    position: relative;
    & > img {
      min-width: 100%;
      height: auto;
    }
  }
}
