.btn--info{
  text-transform: uppercase;
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 15px;
}

.btn--meeting-form{
  text-transform: uppercase;
  font-size: 15px;
  align-self: flex-end;
  margin-top: 15px;
  width: 250px;
  min-height: 40px;
  @media (max-width: 991px) {
    width: 100%;
  }
}

.btn--scheme{
  flex: 0 0 auto;
  margin-top: 10px;
  &:first-of-type{
    margin-top: auto;
  }
  &>img{
    margin-left: 16px;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
}



.units-body {
  color: $main-blue-color;
  font-size: 15px;
}

.unit-item {
  &__flat_description{
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  &__flat_title {
    font-family: "Roboto - Bold";
    font-size: 22px;
    line-height: 22px;
  }
}

.price-card {
  padding: 1.25rem;
  background-color: #f2f2f2;
}
.unit{
  &__name{
    text-transform: uppercase;
  }
  &__attributes {
    padding: 20px;
  }
}
.attr-box{
  &__col{
    display: flex;
    flex-flow: column;
    &:nth-of-type(n+2){
      @media (max-width: 768px) {
        margin-top: 15px;
      }
    }
    &--centered{
      align-self: center;
    }
  }
  &__link{
    color: $main-blue-color !important;
    &:hover{
      text-decoration: none;
    }
    & img{
      padding-left: 6px;
    }
  }
  &__item{
    flex: 0 1 auto;
    &:nth-of-type(even){
      background-color: rgba($main-blue-color, 0.07);
    }
  }
  &__price{
    margin: 15px 0 30px;
  }
  &__img{
    width: 100%;
  }
  &__download{
    flex: 0 1 auto;
    margin-top: auto;
    margin-bottom: 1rem;
    @include respond-below(md) {
      margin-top: 15px;
    }
  }
  &__text{
    &--price{
      font-size: 24px;
      font-weight: 800;
      &>i{
        margin-right: 5px;
      }
    }
  }
}

.info-box{
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 18px;
  color: #004976;
  &__text{
    &--heading{
      font-weight: 800;
    }
  }
  &__col{
    padding: 0 15px 0 0;
    &:nth-of-type(even){
      padding-left: 15px;
      padding-right: 0;
      @media (max-width: 768px) {
        padding-left: 0;
      }
    }
    @media (max-width: 768px) {
      padding: 15px 0 0 0;
    }
  }

  &__body{

  }
  &__header{

  }
  &__content{

  }
  &__footer{

  }

  &__img{
    width: 100%;
  }
}

.info-list{
  padding-left: 0;
  list-style-type: none;
  &__item{
    padding-bottom: 5px;
    &>i{
      margin-right: 15px;
      color: red;
    }
  }
}
.meeting-form{
  border: none;
  border-radius: 0;
  &__text{
    &--subheading{
      font-weight: 800;
    }
    &--gdpr{
      font-size: 0.9rem;
    }
  }
  &__input{
    border-radius: 0;
    border: none;
  }
  &__text-area{
    display: flex;
    flex: 1 1 auto;
    border-radius: 0;
    border: none;
    resize: none;
  }
  &__header{
    margin-bottom: 30px;
  }
  &__body{
    background-color: $main-light-blue-color;
    border: none;
    border-radius: 0;
    padding: 30px;
  }
  &__close-btn{
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    &>i{
      font-size: 32px;
    }
  }
  &__col{
    display: flex;
    flex-flow: column;
  }
}

.price-list{
  &__heading{
    margin-top: 30px;
  }
}

table.units {
  th, td {
    text-align: center;
  }
}
