.breadcrumbs{
  padding-top: 20px;
  padding-bottom: 40px;
  @include respond-above(md) {
    margin-left: -15px;
    margin-right: -15px;
  }
  @include respond-below(md) {
    padding-top: 5px;
    padding-bottom: 20px;
  }
  &__item{
    display: inline-block;
    &:not(:last-of-type){
      padding-right: 5px;
      :after{
        content: ">";
        padding-left: 5px;
      }
    }
  }
  &__link{
    font-size: 14px;
    color: $main-blue-color;
    font-weight: 400;
    @include respond-below(md) {
      font-size: 13px;
    }
  }
}
